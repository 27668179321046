<template>
    <div class="container">
        <div class="return" @click="goTo('/')">
            <img class="img" :src="arrowLeft" alt="">
        </div>
        <iframe id="myIframe" class="iframe" src="https://leisure.intermaps.com/maps/linz" title="map"></iframe>
    </div>
</template>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
    }
    .iframe {
        width: 100%;
        height: 100%;
    }
    .return {
        position: absolute;
        top: 2vh;
        right: 1vw;
        height: 10vh;
        width: 10vh;
        background-color: #104f47;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
    }
    .img {
        width: 90%;
        height: auto;
    }
    .return:hover { background-color: #45845e;}

    @media (max-width: 500px) {
        .return {
            height: 7vh;
            width: 7vh;
        }
    }
</style>

<script>
export default {
    name: 'MapViewr',
    data() {
        return {
            arrowLeft: require('@/assets/MapViewer/arrow_white_left.svg'),
        }
    },
    methods: {
        goTo(to) {
            this.$router.push({path: to}).catch(()=>{});
        },
        test() {
            console.log(document.getElementById('myIframe').contentWindow.window)
        }
    },
    mounted() {
        this.test()
    }
}
</script>