import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n)
Vue.use(VueAxios, axios)

const translations = {
  en: {
      links: {
          map: 'map',
          actual: 'today',
          stand: 'stand'
      },
      landingPage: {
          welcome: 'Welcome to the market',
          headerText: 'The Südbahnhofmarkt is the largest green market in Upper Austria and therefore an attraction for people of all ages far beyond the city limits.',
          highlights: 'Highlights',
          events: 'Events',
          today: 'Stands today',
          checkPrice: 'Check price at the stand',
          noDescriptionProduct: 'This product has no description',
          noDescriptionEvent: 'This event has no description',
          noDescriptionStand: 'This stand has no description'
      },
      standPage: {
        filters: {
          blumen: 'Flowers & Plants',
          brot: 'Bread & Bakery',
          cafe: 'Café, Pastry & Sweets',
          deko: 'Decor & Gift Items, Commodities',
          deinst: 'Services',
          droge: 'Drugstore & Health Products',
          fleisch: 'Meat, Sausage, Poultry & Fish',
          gastro: 'Gastro',
          imbiss: 'Snack',
          inter: 'International Specialties',
          leben: 'Groceries',
          obst: 'Fruits & Vegetables'
        },
        horizontalButtons: {
          fix: 'Fixed Stands',
          mobile: 'Mobile Stands',
          today: 'Who is here today?'
        },
        days: {
          sunday: 'Sunday',
          monday: 'Monday',
          tuesday: 'Tuesday',
          wednesday: 'Wednesday',
          thursday: 'Thursday',
          friday: 'Friday',
          saturday: 'Saturday'
        }
      },
      itemCard: {
        status: {
          closed: 'Closed',
          hours: ' hs'
        },
        text: {
          deals: 'Deals',
          opening: 'Opening Hours',
          contact: 'Contact',
          noInfo: 'No information'
        }
      },
      itemCarouselCard: {
        description: 'Description'
      }
  },
  de: {
      links: {
          map: 'marktplan',
          actual: 'aktuell',
          stand: 'stände'
      },
      landingPage: {
          welcome: 'Willkommen am Markt',
          headerText: 'Der Südbahnhofmarkt ist der größte Grünmarkt Oberösterreichs und daher ein Anziehungspunkt für Menschen aller Altersklassen weit über die Stadtgrenzen hinaus.',
          highlights: 'Highlights',
          events: 'Events',
          today: 'Stände Heute',
          checkPrice: 'Preis am Stand prüfen',
          noDescriptionProduct: 'Für dieses Produkt gibt es keine Beschreibung.',
          noDescriptionEvent: 'Für dieses Ereignis gibt es keine Beschreibung',
          noDescriptionStand: 'Für diese Stände gibt es keine Beschreibung.'
      },
      standPage: {
        filters: {
          blumen: 'Blumen & Pflanzen',
          brot: 'Brot & Gebäck',
          cafe: 'Cafe, Konditorei & Mehlspeisen',
          deko: 'Deko- und Geschenksartikel, Handelswaren',
          deinst: 'Dienstleistungen',
          droge: 'Drogerie & Reformartikel',
          fleisch: 'Fleisch, Wurst, Geflügel & Fisch',
          gastro: 'Gastro',
          imbiss: 'Imbiss',
          inter: 'Internationale Spezialitäten',
          leben: 'Lebensmittel',
          obst: 'Obst & Gemüse'
        },
        horizontalButtons: {
          fix: 'Koje',
          mobile: 'Marktstand',
          today: 'Wer ist heute da?'
        },
        days: {
          sunday: 'Sonntag',
          monday: 'Montag',
          tuesday: 'Dienstag',
          wednesday: 'Mittwoch',
          thursday: 'Donnerstag',
          friday: 'Freitag',
          saturday: 'Samstag'
        }
      },
      itemCard: {
        status: {
          closed: 'Geschlossen',
          hours: ' Uhr'
        },
        text: {
          deals: 'Angebote',
          opening: 'öffnungszeiten',
          contact: 'kontakt',
          noInfo: 'Keine Information'
        }
      },
      itemCarouselCard: {
        description: 'Beschreibung'
      }
  }
}

let lang = undefined;
let kiosk = false;

export function setLang(value) { lang = value }

export function getLang() { return lang }

export function setKiosk(value) {kiosk = value}

export function getKiosk() {return kiosk }

const i18n = new VueI18n({
  messages: translations, // set locale messages
  locale: translations.de, // set locale
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
